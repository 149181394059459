
  .errorCode {
    font-size: 42px;
  }
  
  .errorMessage {
    font-size: 35px;
    margin-top: 50px;
    line-height: 1.5;
  }
  
  .requestStatusCode {
    font-size: 30px;
    margin-top: 50px;
  }
  
  @media (max-width: 600px) {
    .errorCode {
      font-size: 30px;
    }
    .errorMessage {
      font-size: 25px;
    }
    .requestStatusCode {
      font-size: 20px;
    }
  }
  